const productsAndServices = [
	{
		productId: "1",
		productLogo: "https://palmsculpt.s3.us-east-1.amazonaws.com/raptor4.jpg",
		productLogoAlt: "Small Sculpture",
		productName: "Small Sculpture",
		productColor: "#fff",
		productRowHeader: "2' x 2' Sculpture",
		productCardHeader: "Sculpture: 2 feet. x 2 feet",
		productIncludes1: "✓ Quality Craftsmanship",
		productIncludes2: "✓ Handmade",
		productIncludes3: "✓ Customizable",
		productButtonText1: "Add to Cart",
		productButtonText2: "Fast Checkout",
		productPrice: 99.99,
		productLinkURL: "/cart"
	},
	{
		productId: "2",
		productLogo: "https://palmsculpt.s3.us-east-1.amazonaws.com/shark1.jpg",
		productLogoAlt: "Medium Sculpture",
		productName: "Medium Sculpture",
		productColor: "#fff",
		productRowHeader: "4' x 4' Sculpture",
		productCardHeader: "Sculpture: 4 feet. x 4 feet",
		productIncludes1: "✓ Quality Craftsmanship",
		productIncludes2: "✓ Handmade",
		productIncludes3: "✓ Customizable",
		productButtonText1: "Add to Cart",
		productButtonText2: "Fast Checkout",
		productPrice: 199.99,
		productLinkURL: "/cart"
	},
	{
		productId: "3",
		productLogo: "https://palmsculpt.s3.us-east-1.amazonaws.com/horse1.jpg",
		productLogoAlt: "Large Sculpture",
		productName: "Large Sculpture",
		productColor: "#fff",
		productRowHeader: "6' x 6' Sculpture",
		productCardHeader: "Sculpture: 6 feet. x 6 feet",
		productIncludes1: "✓ Quality Craftsmanship",
		productIncludes2: "✓ Handmade",
		productIncludes3: "✓ Customizable",
		productButtonText1: "Add to Cart",
		productButtonText2: "Fast Checkout",
		productPrice: 299.99,
		productLinkURL: "/cart"
	}
];

export default productsAndServices;