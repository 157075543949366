/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import Cards from '../Cards/Cards';
import '../Cards/Cards.css';
import '../Extras/Heroes.css';

function ProductsHeading() {
	return (
		<div>
			<div className="ProductsHero" />
			<div className="d-block my-5 row text-center wrapper-1200">
				<h1 className="main-heading montserrat-text">Products</h1>
				<p className="lg-margin lg-padding monserrat-text xl-paragraph">
				Bring your vision to life with a custom sculpture from Palmsculpt. Whether you are inspired by a favorite animal, 
				a specific theme, or a unique idea, we specialize in creating one-of-a-kind pieces tailored to your preferences. 
				Every custom sculpture is a collaboration, crafted with meticulous attention to detail and a deep respect for the 
				natural materials we work with.
				</p>
				<p className="lg-margin lg-padding monserrat-text xl-paragraph">
				Ordering a custom sculpture is simple. Share your concept, and we&apos;ll guide you through the design process, ensuring 
				the final piece perfectly reflects your vision. With Palmsculpt, you&apos;ll receive a work of art that is not only a stunning 
				centerpiece but also a meaningful expression of creativity and individuality. Let&apos;s create something extraordinary together.
				</p>
				<Link to="/contact">
					<button type="button" className="lg-button lg-margin montserrat-text red-button">Get a Free Quote</button>
				</Link>
			</div>
		</div>
	);
}

function Products(props) {
	return (
		<div className="container-fluid p-0">
			<ProductsHeading />
			<Cards {...props} />
		</div>
	);
}

export default Products;
