/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import '../../index.css';

function Navbar(props) {
	const [menuDisplay, setMenuDisplay] = useState(
		{display: "block"}
	);

	const [closeDisplay, setCloseDisplay] = useState(
		{display: "none"}
	);

	const [navDisplay, setNavDisplay] = useState(
		{display: "none"}
	);

	const menuStyle = {
		displays: {menuDisplay}
	};

	const closeStyle = {
		displays: {closeDisplay}
	}

	const navStyle = {
		displays: {navDisplay}
	}

	const clickMenu = () => {
		// Mobile nav open
		setMenuDisplay(
			{
				display: "none"
			}
		);
		setCloseDisplay(
			{
				display: "block"
			}
		);
		setNavDisplay(
			{
				display: "flex"
			}
		)
	};

	const clickClose = () => {
		// Mobile nav close
		setMenuDisplay(
			{
				display: "block"
			}
		);
		setCloseDisplay(
			{
				display: "none"
			}
		);
		setNavDisplay(
			{
				display: "none"
			}
		)
	};

	return(
		<div id="navbar" className="row">
			<div className="col-12 p-0">

				<div className="navBar wrapper-1200">
					<div className="navBrand">
						<Link to ="/">
							<img src="https://palmsculpt.s3.amazonaws.com/Palmsculpt_Logo_red.svg" alt="Brand logo" />
						</Link>
					</div>

					<div className="nav-wrapper">
						<div className="nav">
							<Link to ="/" className="monserrat-text navLink text-uppercase">Home</Link>
							<Link to ="/products" className="monserrat-text navLink text-uppercase">Products</Link>
							<Link to ="/gallery" className="monserrat-text navLink text-uppercase">Gallery</Link>
							<Link to ="/about" className="monserrat-text navLink text-uppercase">About</Link>
							<Link to ="/contact" className="monserrat-text navLink text-uppercase">Contact</Link>
							<Link to ="/cart" className="monserrat-text navLink text-uppercase">
								<i className="fas fa-shopping-cart" />
								<span className="count-cart">{props.qty}</span>
							</Link>
						</div>
					</div>

				</div>

				<div className="navBar-mobile">

					<div className="navBrand">
						<Link to ="/">
							<img src="https://palmsculpt.s3.amazonaws.com/Palmsculpt_Logo_red.svg" alt="Brand logo" />
						</Link>
					</div>

					<div style={menuStyle.displays.menuDisplay}>
						<div className="montserrat-text navHamburger" onClick={clickMenu}>Menu</div>
					</div>
					<div style={closeStyle.displays.closeDisplay}>
						<div className="montserrat-text navHamburger" onClick={clickClose}>X</div>
					</div>

					<div className="nav-mobile w-100" style={navStyle.displays.navDisplay}>
						<Link to ="/" className="monserrat-text navLink text-uppercase">Home</Link>
						<Link to ="/products" className="monserrat-text navLink text-uppercase">Products</Link>
						<Link to ="/gallery" className="monserrat-text navLink text-uppercase">Gallery</Link>
						<Link to ="/about" className="monserrat-text navLink text-uppercase">About</Link>
						<Link to ="/contact" className="monserrat-text navLink text-uppercase">Contact</Link>
						<Link to ="/cart" className="monserrat-text navLink">
							<i className="fas fa-shopping-cart" />
							<span className="count-cart">{props.qty}</span>
						</Link>
					</div>

				</div>
			</div>
		</div>
	);
}

export default Navbar;
