/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import '../Extras/Heroes.css';
import '../../index.css';

function AboutHeading() {
	return (
		<>
			<div className="AboutHero" />
			<div className="d-block my-5 row text-center wrapper-1200">
				<h1 className="main-heading montserrat-text">About the Artist</h1>
				<p className="lg-margin lg-padding monserrat-text xl-paragraph">
				At the heart of Palmsculpt is Michael Schieffer, a dedicated artisan with a passion for bringing 
				the essence of nature to life through sculpture. With years of experience in working with palm tree bark, 
				he has mastered the art of transforming this humble material into extraordinary representations of life.
				</p>
				<p className="lg-margin lg-padding monserrat-text xl-paragraph">
				Inspired by the forms and movement of wildlife, Michael approaches each piece with the goal of capturing both 
				the strength and grace of the natural world. From the fine details of a shark's textured skin to the powerful 
				stance of a horse, every sculpture is a labor of love and precision.
				</p>

				<p className="lg-margin lg-padding monserrat-text xl-paragraph">
				Based in Mesa, Arizona, Michael uses sustainably sourced palm bark, ensuring each creation honors both nature and 
				artistry. These sculptures are more than decorative; they are enduring works of art that connect viewers to the wild 
				beauty of our world.
				</p>
			</div>
		</>
	);
}

function About() {
	return (
		<div className="container-fluid p-0">
			<AboutHeading />
		</div>
	);
}

export default About;
