/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import '../../index.css';

function Cart({ products = [], onClearCart }) { // Default empty array for products
	const onClick1 = () => {
		onClearCart(); // Call the parent function to clear the cart
	};

	return (
		<div className="container-fluid p-0 my-5">
			{products.length > 0 ? (
				products.map(item => (
					<div key={item.productId} className="m-0 row">
						<div className="col-12 col-md-4 p-0 text-center">
							<img className="w-50" src={item.productLogo} alt="Product Icon" />
						</div>

						<div className="col-12 col-md-4 p-0 text-center">
							<h3 className="lg-margin cart-title">{item.productName}</h3>
							<div className="lg-paragraph monserrat-text">
								<div>{item.productRowHeader}</div>
								<div>{item.productIncludes1}</div>
								<div>{item.productIncludes2}</div>
								<div>{item.productIncludes3}</div>
							</div>
							<button type="button" className="cart-button" onClick={onClick1}>Clear Shopping Cart</button>
						</div>

						<div className="col-12 col-md-4 p-0 text-center">
							<h5 className="padding-20 cart-item-heading">{item.productShortname}</h5>
							<div>Qty: {item.productQuantity}</div>
							<div>Price: ${item.productPrice.toFixed(2)}</div>
							<div>Tax: ${(item.productPrice * 0.0685).toFixed(2)}</div>
							<hr className="hr mx-auto my-0" />
							<div>Total: ${(item.productPrice + (item.productPrice * 0.0685)).toFixed(2)}</div>
							<a href={item.productLinkURL} target="_blank" rel="noreferrer">
								<button type="button" className="btn success-btn md-button montserrat-text red-button">Proceed to Checkout</button>
							</a>
						</div>
					</div>
				))
			) : (
				<p>No products in the cart</p> // Handle empty cart
			)}
		</div>
	);
}

Cart.propTypes = {
	products: PropTypes.arrayOf(PropTypes.shape({
		productId: PropTypes.string.isRequired,
		productLogo: PropTypes.string.isRequired,
		productName: PropTypes.string.isRequired,
		productRowHeader: PropTypes.string,
		productIncludes1: PropTypes.string,
		productIncludes2: PropTypes.string,
		productLinkURL: PropTypes.string.isRequired,
		productQuantity: PropTypes.number.isRequired,
		productPrice: PropTypes.number.isRequired,
	})).isRequired,
	onClearCart: PropTypes.func.isRequired,
};

export default Cart;
