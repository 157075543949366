const pieces = [
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/horse1.jpg"
	},
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/horse2.jpg"
	},
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/horse3.jpg"
	},
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/shark1.jpg"
	},
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/raptor1.jpg"
	},
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/elephant1.jpg"
	},
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/raptor2.jpg"
	},
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/raptor3.jpg"
	},
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/horse4.jpg"
	},
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/horse5.jpg"
	},
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/horse6.jpg"
	},
	{
		alt: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
		title: "Lorem Ipsum",
		imageUrl: "https://palmsculpt.s3.amazonaws.com/shark3.jpg"
	},
];

export default pieces;
