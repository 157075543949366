import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import productsAndServices from '../data/ProductsAndServices';

const ProductsContext = createContext();

export const useProducts = () => useContext(ProductsContext);

export function ProductsProvider({ children }) {
	return (
		<ProductsContext.Provider value={productsAndServices}>
			{children}
		</ProductsContext.Provider>
	);
}

ProductsProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
