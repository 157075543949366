/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function ContactForm() {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		message: ''
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// Replace with your EmailJS user ID and service/template IDs
		const serviceID = 'service_do14xkv';
		const templateID = 'template_eibssdg';
		const userID = '0RK2odBqYW-L-zIAh';

		emailjs.send(serviceID, templateID, formData, userID)
			.then((response) => {
				console.log('Email sent successfully:', response);
				setFormData({ name: '', email: '', message: '' }); // Clear the form
			})
			.catch((error) => {
				console.error('Error sending email:', error);
			});
	};

	return (
		<div className="gray-bg py-5">
			<div className="d-block my-5 row text-center wrapper-1200">
				<h1 className="main-heading montserrat-text">Contact Us for a Free Quote</h1>
				<form className="my-5" onSubmit={handleSubmit}>
					<div>
						<label>Name:</label>
						<input 
							type="text" 
							name="name" 
							value={formData.name} 
							onChange={handleChange} 
							required 
						/>
					</div>
					<div>
						<label>Email:</label>
						<input 
							type="email" 
							name="email" 
							value={formData.email} 
							onChange={handleChange} 
							required 
						/>
					</div>
					<div>
						<label>Message:</label>
						<textarea 
							name="message" 
							value={formData.message} 
							onChange={handleChange} 
							required 
						/>
					</div>
					<button className="lg-button lg-margin montserrat-text my-5 red-button" type="submit">Send Request</button>
				</form>
			</div>
		</div>
	);
}

export default ContactForm;
