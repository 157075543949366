import React from 'react';
import { Link } from 'react-router-dom';
import '../Extras/Heroes.css';
import '../../index.css';

function HomeHeading() {
	return (
		<div>
			<div className="IndexHero" />
			<div className="d-block my-5 row text-center wrapper-1200">
				<h1 className="main-heading montserrat-text">Transforming Spaces with the Beauty of Nature</h1>
				<p className="lg-margin lg-padding monserrat-text xl-paragraph">
				Whether for a private collection, gallery, or corporate space, Palmsculpt&apos;s sculptures make a bold statement. 
				Combining natural textures with intricate detail, these unique works enhance interiors with their organic elegance 
				and artistic sophistication.
				</p>
				<p className="lg-margin lg-padding monserrat-text xl-paragraph">
				Each sculpture from Palmsculpt is a celebration of craftsmanship and nature, designed to captivate and inspire. From 
				the fluid motion of marine life to the dynamic energy of prehistoric creatures, these works add depth and distinction 
				to any setting.
				</p>
				<Link to="/Gallery">
					<button type="button" className="lg-button lg-margin montserrat-text red-button">See Our Gallery</button>
				</Link>
			</div>
		</div>
	);
}

function HomeContent() {
	return (
		<div className='gray-bg py-5'>
			<div className="d-block row text-center wrapper-1200">
				<h1 className="main-heading montserrat-text">Elevating Nature Through Artistry</h1>
				<p className="lg-margin lg-padding monserrat-text xl-paragraph">
				At Palmsculpt, we craft lifelike sculptures from palm tree bark, showcasing the perfect balance of artistry and natural 
				beauty. Each piece reflects meticulous craftsmanship and a deep appreciation for the elegance found in nature&apos;s raw 
				materials.
				</p>
				<p className="lg-margin lg-padding monserrat-text xl-paragraph">
				Each sculpture is more than a piece of art—it&apos;s a celebration of nature&apos;s raw elegance, carefully shaped to bring warmth and 
				character to any space. At Palmsculpt, we believe that true artistry lies in revealing the hidden potential within natural 
				materials, creating works that inspire connection and admiration.
				</p>
				<Link to="/Products">
					<button type="button" className="lg-button monserrat-text lg-margin red-button">Order Today</button>
				</Link>
			</div>
		</div>
	);
}

function Home() {
	return (
		<div className="container-fluid p-0">
			<HomeHeading />
			<HomeContent />
		</div>
	);
}

export default Home;
