import React from 'react';
import { Link } from 'react-router-dom';
import './Gallery.css';
import '../Extras/Heroes.css';
import '../../index.css';
import GalleryDisplay from './GalleryDisplay';

function GalleryHeading() {
	return (
		<>
			<div className="GalleryHero" />
			<div className="d-block my-5 row text-center wrapper-1200">
				<h1 className="main-heading montserrat-text">Inspiring Sculptures</h1>
				<p className="lg-margin lg-padding monserrat-text xl-paragraph">
				More than just art, Palmsculpt’s creations are immersive experiences. Each sculpture invites 
				viewers to connect with the natural world while appreciating the skill and vision behind its 
				creation. Palmsculpt’s approach to art transforms palm bark into compelling works that are as 
				inspiring as they are distinctive. These sculptures embody a harmonious blend of raw natural 
				beauty and skilled design.
				</p>
				<Link to="/Products">
					<button type="button" className="lg-button monserrat-text lg-margin red-button">Order Today</button>
				</Link>
			</div>
		</>
	);
}

function Gallery() {
	return (
		<div className="container-fluid p-0">
			<GalleryHeading />
			<GalleryDisplay />
		</div>
	);
}

export default Gallery;
