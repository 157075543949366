import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Products from './components/Products/Products';
import Gallery from './components/Gallery/Gallery';
import About from './components/About/About';
import Cart from './components/Cart/Cart';
import { ProductsProvider } from './contexts/ProductsContext';
import productsAndServices from './data/ProductsAndServices';
import ContactForm from './components/Contact/ContactForm';

function App() {
	const [carts, setCarts] = useState({
		productId: "",
		productName: "Nothing in Cart",
		productQuantity: 0,
		productPrice: 0.00,
		productRowHeader: "",
		productIncludes1: "",
		productIncludes2: "",
		productIncludes3: "",
		productLogo: "https://bryandoconnor.s3.us-west-2.amazonaws.com/images/NoItem-icon.svg",
		productLinkURL: "/cart"
	});

	const emptyCart = () => {
		setCarts({
			productId: "",
			productName: "Nothing in Cart",
			productQuantity: 0,
			productPrice: 0.00,
			productRowHeader: "",
			productIncludes1: "",
			productIncludes2: "",
			productIncludes3: "",
			productLogo: "https://bryandoconnor.s3.us-west-2.amazonaws.com/images/NoItem-icon.svg",
			productLinkURL: "/cart"
		});
	};

	const chooseClick = (e) => {
		const selectedProductId = e.currentTarget.id;
		const selectedProduct = productsAndServices.find(product => product.productId === selectedProductId);

		if (selectedProduct) {
			setCarts({
				productId: selectedProduct.productId,
				productName: selectedProduct.productName,
				productShortname: selectedProduct.productShortname,
				productQuantity: 1,
				productPrice: selectedProduct.productPrice,
				productRowHeader: selectedProduct.productRowHeader,
				productIncludes1: selectedProduct.productIncludes1,
				productIncludes2: selectedProduct.productIncludes2,
				productIncludes3: selectedProduct.productIncludes3,
				productLogo: selectedProduct.productLogo,
				productLinkURL: selectedProduct.productLinkURL
			});
		}
	};

	return (
		<>
			<Navbar qty={carts.productQuantity} />

			<ProductsProvider> {/* Wrap the Routes with ProductsProvider */}
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route
						exact path="/products"
						element={
							<Products
								productId={carts.productId}
								productName={carts.productName}
								productShortname={carts.productShortname}
								productQuantity={carts.productQuantity}
								productPrice={carts.productPrice}
								productRowHeader={carts.productRowHeader}
								productIncludes1={carts.productIncludes1}
								productIncludes2={carts.productIncludes2}
								productIncludes3={carts.productIncludes3}
								productLogo={carts.productLogo}
								productLinkURL={carts.productLinkURL}
								onClickEvent={chooseClick}
								onClick1={emptyCart}
							/>
						}
					/>
					<Route exact path="/gallery" element={<Gallery />} />
					<Route exact path="/about" element={<About />} />
					<Route exact path="/contact" element={<ContactForm />} />
					<Route
						exact path="/cart"
						element={
							<Cart
								products={[carts]}  // Ensure you're passing the correct prop name
								onClearCart={emptyCart} // Ensure the method name matches in Cart
							/>
						}
					/>
				</Routes>
			</ProductsProvider>

			<Footer />
		</>
	);
}

export default App;
